import React, { FC, memo } from 'react'
// Components
import FormControl from '../../../form-control'
// Types
import { AttributeData, EavResourceType, FormAttribute, TOptions } from 'modules/new-entity/types'
import { Grid } from '@material-ui/core'

type TProps = {
  attrData: AttributeData
  name: string
  index: number
  options: TOptions
  selfType: 'entity' | 'widget'
  resourceType: EavResourceType
  disabled?: boolean
}

const RenderFormControls: FC<TProps> = (props) => {
  const { attrData, name, index, options, selfType, disabled, resourceType } = props
  const { setAttributes } = attrData

  if (!setAttributes || !setAttributes.length) return null
  return (
    <Grid container spacing={2}>
      {setAttributes.map((attrItem: FormAttribute) => {
        return (
          <FormControl
            resourceType={resourceType}
            nested
            selfType={selfType}
            data={attrItem}
            key={`${name}[${index}]${attrItem.attribute['@id']}`}
            name={`${name}[${index}]${attrItem.attribute['@id']}`}
            // options
            options={options}
            allData={setAttributes}
            pathToHierarchicalOptions={`${name}.${attrItem.attribute['@id']}`}
            pathForConditions={`${name}[${index}]`}
            disabled={disabled}
          />
        )
      })}
    </Grid>
  )
}

export default memo(RenderFormControls)
