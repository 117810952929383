import React, { FC, ReactNode } from 'react'
import {
  Paper,
  Box,
  Tooltip,
  Icon,
  IconButton,
  Typography,
  makeStyles,
  Collapse,
  LinearProgress,
} from '@material-ui/core'
import { ChevronRight } from '@material-ui/icons'
import { Draggable } from 'react-beautiful-dnd'
import clsx from 'clsx'
import { useQuery } from 'react-query'
import { getIdFromIri } from 'core/utils'
import { httpService } from 'core/data'
import GroupError from '../../../group-error'

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(0.5),
    border: '1px solid transparent',
    '&:has(.Mui-error)': {
      borderColor: theme.palette.error.main,
    },
  },
  hasError: {
    borderColor: theme.palette.error.main,
  },
  dragBtn: {
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'grab',
  },
  title: {
    '&:first-letter': {
      textTransform: 'capitalize',
    },
  },
}))

type Props = {
  id: string
  index: number
  title: string
  errorsCount: number
  isOpen: boolean
  moveTitle?: string
  expandTitle?: string
  actions?: ReactNode
  hasError?: boolean
  onChangeOpen: () => void
}

export const ArrayItem: FC<Props> = ({
  id,
  index,
  children,
  title: titleProp,
  errorsCount,
  isOpen,
  moveTitle,
  expandTitle,
  actions,
  hasError,
  onChangeOpen,
}) => {
  const classes = useStyles()

  const { data: title, isFetching } = useTitleController(titleProp)

  return (
    <Draggable draggableId={id.toString()} index={index}>
      {(provided) => (
        <Box py={0.5}>
          <Paper
            ref={provided.innerRef}
            className={clsx(classes.root, { [classes.hasError]: hasError })}
            {...provided.draggableProps}
          >
            <Box display="flex">
              <Tooltip placement="top" title={moveTitle || 'Move'}>
                <div className={classes.dragBtn} {...provided.dragHandleProps}>
                  <Icon className="icon-dragindrop" />
                </div>
              </Tooltip>
              <Box display="flex" position="relative" flex={1} alignItems="center" p={2} pl={1}>
                <Box position="absolute" style={{ inset: 0 }} onClick={onChangeOpen} />
                <Tooltip placement="top" title={expandTitle || 'Expand'}>
                  <IconButton size="small" disableRipple onClick={onChangeOpen} edge="start">
                    <ChevronRight style={{ transform: `rotate(${isOpen ? 90 : 0}deg)` }} />
                  </IconButton>
                </Tooltip>
                <Box ml={1} display="flex" alignItems="center">
                  <Typography variant="body1" component="div" className={classes.title}>
                    {isFetching ? <LinearProgress style={{ width: 230 }} /> : title}
                  </Typography>
                  <GroupError count={errorsCount} />
                </Box>
                <Box ml="auto">{actions}</Box>
              </Box>
            </Box>
            <Collapse in={isOpen}>
              <Box px={3} py={2}>
                {children}
              </Box>
            </Collapse>
          </Paper>
        </Box>
      )}
    </Draggable>
  )
}

export function useTitleController(titleValue: string) {
  const needRequest = titleValue.includes('/api')
  const resource = needRequest && titleValue.split('/')[2]
  const entityId = getIdFromIri(titleValue)

  return useQuery(
    [resource, entityId],
    () =>
      httpService.get<{ name: string }>(`/${resource}/${entityId}`).then((res) => res.data.name),
    {
      enabled: needRequest,
      initialData: titleValue,
    }
  )
}
